<template>
  <!-- Modal -->
  <div
    class="modal fade cart-popup"
    id="cartModal"
    tabindex="-1"
    aria-labelledby="cartModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cartModalLabel">
            {{ props.translationText.text.cart }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ri-close-line"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="order-review">
            <table>
              <tbody>
                <template v-for="(cartItem, index) in cartData.getCartItems">
                  <tr>
                    <td class="thumb">
                      <img :src="cartItem.hoverImage" alt="" />
                    </td>
                    <td>
                      <h4 v-html="cartItem.name"></h4>
                      <p v-html="cartItem.description"></p>
                      <p class="onetime" v-if="cartItem.purchaseType == 1">
                        {{ props.translationText.text.oneTime }}
                      </p>
                      <p class="onetime" v-if="cartItem.purchaseType == 2">
                        {{ props.translationText.text.everyMonth }}
                      </p>
                      <div class="wrapper_qty">
                        <button
                          type="button"
                          @click="
                            cartData.decrementQ(
                              cartItem,
                              userLogin.checkIsLoggedIn,
                            
                              userLogin.getUserUserData
                            )
                          "
                          class="cclwminus"
                        >
                          -
                        </button>
                        {{ cartItem.quantity }}

                        <button
                          type="button"
                          @click="
                            cartData.incrementQ(
                              cartItem,
                              userLogin.checkIsLoggedIn,
                              
                              userLogin.getUserUserData
                            )
                          "
                          class="cclwplus"
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td>
                      <div class="ds-order">
                        <a
                          href="javascript: ;"
                          @click="cartData.removeFromCart(cartItem)"
                          class="remove"
                          ><i class="ri-delete-bin-line"></i
                        ></a>
                        <span class="price"
                          >${{
                            (cartItem.quantity * cartItem.price).toFixed(2)
                          }}</span
                        >
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="footer-area">
          <div class="coupon">
            <template v-if="!cartData.getCartCoupon">
              <input
                type="text"
                v-model="coupon_code"
                name="coupon_code"
                class="input-text"
                id="coupon_code"
                value=""
                :placeholder="props.translationText.text.applyCode"
              />
              <button
                type="button"
                @click="applyCode"
                class="btn btn-dark rc"
                name=""
              >
                {{ props.translationText.text.apply }}
              </button>
            </template>

            <template v-else>
              <p>{{ cartData.getCartCouponCode }} Applied</p>
              <button
                type="button"
                @click="removeApplyCode"
                class="btn btn-dark rc"
                name=""
              >
                <i class="ri-delete-bin-line"></i>
              </button>
            </template>
          </div>
          <router-link
            @click="navigate"
            class="btn btn-dark rc"
            :to="{ name: 'Checkout' }"
            >{{ props.translationText.text.checkout }} ${{
              cartData.getCartTotalNetPrice
            }}</router-link
          >
          <!-- <a href="javascript: ;" class="btn btn-dark rc">Checkout $54.50</a> -->
          <router-link
            @click="navigateProduct"
            to="/product"
            class="btn btn-outline-dark rc"
            >{{ props.translationText.text.addMoreProducts }}</router-link
          >
          <!-- <a href="javascript: ;" class="btn btn-outline-dark rc">Add More Products</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";

const props = defineProps(["translationText", "baseURL"]);
const cartData = useCartStore();
//const cartItemData = ref([])
const router = useRouter();
const userLogin = useLoginStore();
const quantity = ref(1);
const coupon_code = ref();
const isCoupnApply = ref(false);

console.log("getUserUserData",userLogin.getUserUserData);

const navigate = () => {
  $("#cartModal").modal("hide");
  router.push({ name: "Checkout" });
};
const navigateProduct = () => {
  $("#cartModal").modal("hide");
  router.push({ name: "Product" });
};

const applyCode = () => {
  // cartData.applyCoupon(coupon_code.value);
  // isCoupnApply.value = true;
  if(cartData.getCartPurchaseType == true){
    swal({
        position: "top-end",
        icon: "error",
        title: "Autoship discount price is already applied.",
        button: false,
        timer: 2500,
      });
  }
  else{
    axios
    .post(
      props.baseURL + "discount",
      { name: coupon_code.value,qty:cartData.getCartCountQuantity },
      {
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    )
    .then((response) => {
      //allStates.value = response.data.states;
      cartData.applyCoupon(response.data, coupon_code.value);
      console.log("getCartCoupon", cartData.getCartCoupon);
      swal({
        position: "top-end",
        icon: "success",
        title: "Coupon code applied",
        button: false,
        timer: 2500,
      });
    })
    .catch((error) => {
      swal({
        position: "top-end",
        icon: "error",
        title: "Coupon code not found",
        button: false,
        timer: 2500,
      });
    });
  }
  
};
const removeApplyCode = () => {
  cartData.resetApplyCoupon();
  coupon_code.value = "";
  isCoupnApply.value = false;
};
const decreaseQty = () => {
  if (quantity.value > 1) {
    quantity.value--;
  }
};

const increaseQty = () => {
  quantity.value++;
};
const cartItem = {
  id: 1,
};
const openCartModal = () => {
  cartData.addToCart(cartItem);
  $("#cartModal").modal("show");
};
onMounted(() => {
  console.log("cartItemData on mounted ");
});
</script>
